import { ChainId } from '@sparkpointio/sparkswap-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x98e8EB714dBBBAaC631A6866BD57423d15d53043', // TODO
  [ChainId.BSCTESTNET]: '0x98e8EB714dBBBAaC631A6866BD57423d15d53043',
  [ChainId.ETHMAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.ETHTESTNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
