import React, { useState } from 'react'

const dataCleaner = (data) => {
  const tmp = data.filter((t) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])/
    if (t.length === 2) {
      return t[1].match(regex) || t[1].match(/[a-zA-z]/)
    }
    return t.length < 2 || t.length > 2
  })

  return tmp
}

export const filterData = (data) => {
  const filteredData = data.split('\n').map((x) =>
    x
      .split(',')
      .map((y) => y.trim())
      .filter((z) => z !== '')
  )
  return filteredData
}

export const checkDuplicate = (arr) => arr.filter((item, ind) => arr.indexOf(item) !== ind)
export const mergeDuplicates = (arr) => {
  const result: any[][] = []

  // Array.from(new Set(
  //   arr
  //   .map(x => {
  //     const ln = Object.keys(x)[0]
  //     return x[ln].address
  //   })
  //   .filter(x => {
  //     return result.push(
  //       arr
  //       .filter(y => {
  //         const ln = Object.keys(y)[0]
  //         return y[ln].address === x
  //       })
  //       .reduce((output, item) => {

  //         const ln = Object.keys(output)[0]
  //         // const val = output[ln].address === undefined ? 0 : output[ln].address
  //         return output[ln].address

  //       })
  //     )
  //   })
  // ))

  return result
  // const result:any[][] = [];
  // Array.from(new Set(arr.map(x => [0]).filter(x => {
  //   return result.push(
  //     arr.filter(y => y[0] === x).reduce((output, item) => {
  //       const val = output[1] === undefined ? 0 : output[1]
  //       output[0] = x
  //       output[1] = (parseInt(item[1]) + parseInt(val.toString())).toString()
  //       return output
  //     })
  //   )
  // })))
  // return result;
}

export default dataCleaner
