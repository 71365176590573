import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react'
import { Flex, Link, Modal, Text } from '@sparkpointio/sparkswap-uikit'
import { Currency, Token } from '@sparkpointio/sparkswap-sdk'
import TablePagination from '@mui/material/TablePagination'
import styled, { ThemeContext } from 'styled-components'
import { ErrorText, SwapButton } from 'components/swap/styleds'
import ReactLoading from 'react-loading'
import { AlertTriangle } from 'react-feather'
import { Sampledata } from './types'
import { useBurnActionHandlers } from '../../state/burn/hooks'
import './style.css'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useMultisend } from '../../hooks/useMultisend'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { hasEnoughBalance, hasEnoughFeeBalance, hasEnoughSendBalance } from '../../utils/multisend'
import TxSummaryCsvModal from './TxSummaryCsvModal'

type ApproveModalProps = {
  onDismiss?: () => void
  action?: Dispatch<{ type: string }>
  csvData: string[][]
  options?: any
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 40px;
  max-height: 70vh;
  @media (max-width: 600px) {
    margin: auto;
  }
`

const StyledFooter = styled(Flex)`
  // max-width: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
  border-radius: 6px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  text-align: center;
  @media (max-width: 500px) {
    min-height: 30px;
  }
`

const WebsiteColumn = styled.div`
  display: grid;
`

const TwoColumnHeader = styled(WebsiteColumn)`
  grid-template-columns: repeat(2, 2fr);
  // grid-template-columns: 1fr auto;
  // grid-template-rows: 1;
  width: 100%;
  // max-width: 2000px;
  height: auto;
  text-align: auto;

  column-gap: 10px;
  row-gap: 10px;
  @media (max-width: 1100px) {
    // grid-template-columns: repeat(1, 1fr);
    grid-template-columns: repeat(auto, 1fr);
    width: 100%;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }
`

const StyledHr = styled.hr`
  border: 1px solid ${({ theme }) => theme.dexTheme.colors.accent2};
  margin: 1em 0 0 0;
`

const ApprovalTextData = styled(Text)`
  // font-weight: bold;
  font-size: 28px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
`

const RecipientsWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 30px 10px;
  @media (max-width: 500px) {
    margin: 20px auto;
  }
`

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  @media (max-width: 450px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const Option = styled.div`
  padding: 0;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 450px) {
    align-items: space-between;
    width: 100%;
    margin: 5px;
  }
`

const StyledButtonLink = styled(Link)`
  width: 100%;
  text-decoration: none !important;
`

const TxHashText = styled(Text)`
  overflow: hidden;
  max-inline-size: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 650px) {
    max-inline-size: 300px;
  }
`

const RecipientAmountText = styled(Text)`
  text-align: right;
  @media (max-width: 600px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    text-align: left;
  }
`

const RecipientAddressText = styled(Text)`
  overflow: visible;
  max-inline-size: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 500px) {
    overflow: hidden;
    max-inline-size: 320px;
  }
`

const RecipientsDataWrapper = styled.div`
  margin: auto;
  @media (max-width: 500px) {
    margin: 10px auto;
  }
`

const ApprovalDataHeading = styled(Text)`
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
`

const PageSize = 3
const defaultOnDismiss = () => null

const ApproveModal = ({ onDismiss, action, csvData, options }: ApproveModalProps) => {
  const {
    totalAmtToSend,
    sendCurrency,
    feeToken,
    feeTokenAmt,
    tokenList,
    multisendContract,
    account,
    chainId,
    editorRef,
    isSubscribed,
    setTokenSent,
  } = options

  const theme = useContext(ThemeContext)
  const [sendApproval, sendApprove] = useApproveCallback(totalAmtToSend.current, multisendContract)
  const [feeApproval, approveFeeToken] = useApproveCallback(options.feeTokenAmt.current, multisendContract)
  const ether = Currency.getEther(chainId)
  const feeTokenInfo = tokenList[feeToken ?? ''] ?? (ether as Token)
  const [feeApprovalPending, setFeeApprovalPending] = useState(false)
  const [sendApprovalPending, setSendApprovalPending] = useState(false)
  const [sendPending, setSendPending] = useState(false)

  const [sendApproved, setSendApproved] = useState(sendApproval === ApprovalState.APPROVED)
  const [feeTokenApproved, setFeeTokenApproved] = useState(feeApproval === ApprovalState.APPROVED)
  const [pagination, setPagination] = useState({ page: 0, count: csvData.length, rowsPerPage: PageSize })
  // const [list, setList] = useState(pagination.rowsPerPage > 0 ? csvData.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage) : csvData)
  const list = useMemo(() => {
    const data =
      pagination.rowsPerPage > 0
        ? csvData.slice(
            pagination.page * pagination.rowsPerPage,
            pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
          )
        : csvData
    return data
  }, [csvData, pagination])
  const [txHash, multisend] = useMultisend(csvData, options, editorRef)

  const sendTokenBalance = useCurrencyBalance(account ?? '', sendCurrency, chainId)

  const feeTokenBalance = useCurrencyBalance(account ?? '', feeTokenInfo, chainId)
  const hasEnoughSendBal = hasEnoughSendBalance(account, chainId, sendTokenBalance, totalAmtToSend.current)
  const hasEnoughFeeBal = isSubscribed
    ? true
    : hasEnoughFeeBalance(account, chainId, feeTokenBalance, feeTokenAmt.current)

  const hasEnoughBal = hasEnoughBalance(
    account,
    chainId,
    sendCurrency,
    feeTokenInfo,
    sendTokenBalance,
    totalAmtToSend.current,
    feeTokenBalance,
    feeTokenAmt.current,
    isSubscribed
  )

  const d = {
    totalSentTokens: `${totalAmtToSend?.current.toExact()} ${sendCurrency?.symbol}`,
    platformFee: isSubscribed ? 'waived' : `${feeTokenAmt?.current.toExact()} ${feeTokenInfo?.symbol}`,
    totalRecipients: csvData.length,
    sendTokenBalance: `${sendTokenBalance?.toExact()} ${sendCurrency?.symbol}`,
    feeTokenBalance: isSubscribed ? 'N/A' : `${feeTokenBalance?.toExact()} ${feeTokenInfo?.symbol}`,
    sendToken: `${sendCurrency?.symbol} token`,
    recipients: csvData,
  }
  // recipients: any

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination({ ...pagination, page: newPage })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPagination({ ...pagination, page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  }

  const isSending = (sending = false) => {
    setSendPending(sending)
  }

  // wrapped onUserInput to clear signatures
  // const onUserInput = useCallback(
  //   (field: Field, val: string) => {
  //     setSignatureData(null)
  //     return _onUserInput(field, val)
  //   },
  //   [_onUserInput]
  // )
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  // const isValid = !error

  const isFeeTokenApproved = () => {
    if (!feeTokenInfo) {
      return true
    }

    return (feeTokenInfo && feeApproval === ApprovalState.APPROVED) || isSubscribed
  }
  const onApproveClick = async () => {
    try {
      if (!isFeeTokenApproved()) {
        setFeeApprovalPending(true)
        await approveFeeToken()
      } else {
        setSendApprovalPending(true)
        await sendApprove()
      }
    } catch (e) {
      console.error(e)
      if (!isFeeTokenApproved()) {
        setFeeApprovalPending(false)
        setFeeTokenApproved(false)
      } else {
        setSendApprovalPending(false)
        setSendApproved(false)
      }
    }
  }

  const RecipientsContainer = ({ data, amount, currency = 'SFUEL' }: Sampledata) => {
    return (
      <RecipientsDataWrapper>
        <TwoColumnHeader>
          <RecipientAddressText color="textSubtle">{data}</RecipientAddressText>
          <RecipientAmountText>
            {amount} {currency}{' '}
          </RecipientAmountText>
        </TwoColumnHeader>
        <StyledHr />
        <br />
      </RecipientsDataWrapper>
    )
  }

  useEffect(() => {
    if (sendApproval === ApprovalState.APPROVED && sendApprovalPending) {
      setSendApprovalPending(false)
    }
    if (feeApproval === ApprovalState.APPROVED && feeApprovalPending) {
      setFeeApprovalPending(false)
    }
    if (txHash) {
      setTokenSent(true)
    }
  }, [options.sendApproval, feeApproval, sendApproval, feeApprovalPending, sendApprovalPending, txHash, setTokenSent])

  return (
    <>
      {txHash && <TxSummaryCsvModal data={{ txHash, ...d }} onDismiss={onDismiss} />}
      {!txHash && (
        <Modal title="" onDismiss={onDismiss} bodyPadding="15px" background="#15151A" border="2px solid #39BEEC">
          <ModalContainer>
            <TwoColumnHeader>
              <StyledContainer>
                <Flex flexDirection="column">
                  <ApprovalTextData>
                    {totalAmtToSend.current.toSignificant(10) ?? (
                      <ReactLoading type="bubbles" width="21px" height="21px" />
                    )}{' '}
                    {sendCurrency?.symbol}
                  </ApprovalTextData>
                  <ApprovalDataHeading>Tokens to send</ApprovalDataHeading>
                </Flex>
              </StyledContainer>
              <StyledContainer>
                <Flex flexDirection="column">
                  {(!hasEnoughSendBal || (sendCurrency?.symbol === feeTokenInfo?.symbol && !hasEnoughBal)) && (
                    <ErrorText fontSize="14px" severity={3}>
                      <AlertTriangle color={theme.colors.failure} />
                      &nbsp;
                      <span style={{ verticalAlign: 'super' }}>INSUFFICIENT</span>
                    </ErrorText>
                  )}
                  <ApprovalTextData>
                    {sendTokenBalance?.toSignificant(10) ?? <ReactLoading type="bubbles" width="21px" height="21px" />}{' '}
                    {sendCurrency?.symbol}
                  </ApprovalTextData>
                  {sendCurrency?.symbol === feeTokenInfo?.symbol ? (
                    <ApprovalDataHeading>Token/Fee balance</ApprovalDataHeading>
                  ) : (
                    <ApprovalDataHeading>Token balance</ApprovalDataHeading>
                  )}
                </Flex>
              </StyledContainer>
              <StyledContainer>
                <Flex flexDirection="column">
                  {isSubscribed ? (
                    <ApprovalTextData>Waived</ApprovalTextData>
                  ) : (
                    <ApprovalTextData>
                      {feeTokenAmt?.current.toSignificant(10)}&nbsp;
                      {feeTokenInfo?.symbol}
                      {!feeTokenInfo && ether.symbol}
                    </ApprovalTextData>
                  )}

                  <ApprovalDataHeading>Total Fee</ApprovalDataHeading>
                </Flex>
              </StyledContainer>
              {sendCurrency?.symbol !== feeTokenInfo?.symbol && !isSubscribed && (
                <StyledContainer>
                  <Flex flexDirection="column">
                    {feeTokenBalance?.lessThan(feeTokenAmt?.current) && (
                      <ErrorText fontSize="14px" severity={3}>
                        <AlertTriangle color={theme.colors.failure} />
                        &nbsp;
                        <span style={{ verticalAlign: 'super' }}>INSUFFICIENT</span>
                      </ErrorText>
                    )}
                    <ApprovalTextData>
                      {feeTokenInfo
                        ? feeTokenBalance?.toSignificant(10) ?? (
                            <ReactLoading type="bubbles" width="21px" height="21px" />
                          )
                        : sendTokenBalance?.toFixed(8)}
                      &nbsp;
                      {feeTokenInfo?.symbol}
                      {!feeTokenInfo && sendTokenBalance?.currency.symbol}
                    </ApprovalTextData>
                    <ApprovalDataHeading>Fee balance</ApprovalDataHeading>
                  </Flex>
                </StyledContainer>
              )}
              <StyledContainer>
                <Flex flexDirection="column" alignItems="center">
                  <ApprovalTextData>
                    {ApprovalState.APPROVED ? (
                      csvData.length
                    ) : (
                      <ReactLoading type="bubbles" width="21px" height="21px" />
                    )}
                  </ApprovalTextData>
                  <ApprovalDataHeading>Total number of addresses</ApprovalDataHeading>
                </Flex>
              </StyledContainer>
            </TwoColumnHeader>

            <RecipientsWrapper>
              <Text style={{ textAlign: 'left' }}>List of Recipients</Text>
              <div style={{ width: '100%' }}>
                {list.map((item, ind) => {
                  const counter = ind + 1
                  const amount = item[1]

                  return (
                    <RecipientsContainer key={counter} data={item[0]} amount={amount} currency={sendCurrency.symbol} />
                  )
                })}
              </div>
              <TablePagination
                component="div"
                rowsPerPageOptions={[3, 5, 10, 25]}
                count={csvData.length}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </RecipientsWrapper>

            {/* <StyledFooter>
              <Flex flexDirection="column" mb="14px" style={{width: '100%'}}>
                <Text style={{textAlign: 'left'}}>Amount to approve</Text>
                <StyledOptions>
                  <Option key='0'>
                    <Radio
                      scale="sm"
                      name="Approve_amount"
                      disabled={approved}
                      onChange={(e) => setIsUnliApprove(false)}
                    />
                    <Text color="textSubtle" ml="10px">
                      Exact Amount to be sent
                    </Text>
                  </Option>
                  <Option key='1'>
                    <Radio
                      scale="sm"
                      name="Approve_amount"
                      disabled={approved}
                      onChange={(e) => setIsUnliApprove(true)}
                    />
        {/*             <Text color="textSubtle" ml="10px"> */}
            {/*                 Unlimited amount */}
            {/*             </Text> */}
            {/*         </Option> */}
            {/*     </StyledOptions> */}
            {/*   </Flex> */}
            {/* </StyledFooter> */}
            {/* <Flex flexDirection="column" style={{ width: '100%' }}> */}
            {/*   {(sendApprovalPending || feeApprovalPending) && ( */}
            {/*     <Text color="textSubtle" mt="10px"> */}
            {/*       Initiating transactions... */}
            {/*     </Text> */}
            {/*   )} */}
            {/* {approved && !loading && ( */}
            {/*   <> */}
            {/*     <Text style={{ textAlign: 'left' }}>Approve Transaction Hash</Text> */}
            {/*     <TxHashText color="textSubtle"> */}
            {/*       0x0b03c337022fceec50e06ad1c65f55a697cd03cd09d672c51803dc6b060b013e */}
            {/*     </TxHashText> */}
            {/*   </> */}
            {/* )} */}
            {/* <Link external href={getBscScanLink(transactionAddress, 'address')}> */}

            {/* </Link> */}
            {/* </Flex> */}
            <div style={{ width: '100%', padding: '1.5rem 0' }}>
              {sendApproval === ApprovalState.APPROVED &&
              (!feeToken || feeApproval === ApprovalState.APPROVED || isSubscribed) ? (
                <SwapButton
                  fullWidth
                  onClick={() => {
                    multisend({ onDismiss, isSending }).catch((e) => console.error('Please try again', e))
                  }}
                  disabled={!hasEnoughSendBal || !hasEnoughFeeBal || sendPending}
                >
                  {sendPending ? <ReactLoading type="bubbles" width="21px" height="21px" /> : 'Send'}
                </SwapButton>
              ) : (
                <SwapButton
                  fullWidth
                  onClick={onApproveClick}
                  disabled={
                    feeApproval === ApprovalState.UNKNOWN ||
                    sendApproval === ApprovalState.UNKNOWN ||
                    feeApprovalPending ||
                    sendApprovalPending ||
                    (feeTokenBalance?.lessThan(feeTokenAmt.current) && !isSubscribed)
                  }
                >
                  {(sendApprovalPending || feeApprovalPending) && 'Approving...'}
                  {(feeApproval === ApprovalState.UNKNOWN || sendApproval === ApprovalState.UNKNOWN) && (
                    <ReactLoading type="bubbles" width="21px" height="21px" />
                  )}
                  {!sendApprovalPending &&
                    !feeApprovalPending &&
                    feeApproval !== ApprovalState.UNKNOWN &&
                    sendApproval !== ApprovalState.UNKNOWN &&
                    (feeApproval !== ApprovalState.APPROVED && !isSubscribed
                      ? `Approve ${feeTokenInfo?.symbol} as Fee token`
                      : `Enable sending of ${sendCurrency.symbol}`)}
                </SwapButton>
              )}
            </div>
            {/* {loading && <Text color="textSubtle" mt="10px">Preparing transactions...</Text>} */}
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

export default ApproveModal
