import { ChainId } from '@sparkpointio/sparkswap-sdk'
import { MULTISEND_SFUEL_FEE, MULTISEND_SRK_FEE } from '../constants'
import { multisendFees } from '../constants/multisend'
import { toBigNumber } from './formatBalance'

// export const getFee = async (contract) => {
//     const fees = await contract.methods.getFee().call()
//
//     return {
//         'srkb': fees[0],
//         'sfuel': fees[1],
//         'bnb': fees[2],
//     }
// }

export const userHasEnoughBalance = (
  account,
  chainId,
  sendTokenBalance,
  sendTokenAmount,
  feeToken?,
  feeTokenBalance?,
  feeTokenAmt?
) => {
  const hasEnoughSendBalance = !sendTokenBalance?.lessThan(sendTokenAmount)
  const hasEnoughFeeBalance = !feeTokenBalance?.lessThan(feeTokenAmt)

  return hasEnoughSendBalance && hasEnoughFeeBalance
}

export const hasEnoughSendBalance = (account, chainId, sendTokenBalance, sendTokenAmount) => {
  return !sendTokenBalance?.lessThan(sendTokenAmount)
}

export const hasEnoughFeeBalance = (account, chainId, feeTokenBalance, feeTokenAmt) => {
  return !feeTokenBalance?.lessThan(feeTokenAmt)
}

export const hasEnoughBalance = (
  account,
  chainId,
  sendCurrency,
  feeTokenInfo,
  sendTokenBalance,
  sendTokenAmount,
  feeTokenBalance,
  feeTokenAmt,
  isSubscribed
) => {
  try {
    const sendBal = toBigNumber(sendTokenBalance?.toExact())
    const feeBal = toBigNumber(feeTokenBalance?.toExact())
    const sendAmount = toBigNumber(sendTokenAmount.toExact())
    const feeAmount = toBigNumber(feeTokenAmt.toExact())
    if (sendCurrency?.symbol === feeTokenInfo?.symbol) {
      const totalSend = sendAmount.plus(isSubscribed ? toBigNumber('0') : feeAmount) // todo: test this
      return sendBal?.gte(totalSend)
    }
    const sendBalEnough = sendBal.gte(sendAmount)
    const feeBalEnough = feeBal.gte(feeAmount)
    return sendBalEnough && feeBalEnough
  } catch (e) {
    console.error(e)
  }
  return false
}

export const getDefaultFee = (chainId = ChainId.MAINNET) => {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.BSCTESTNET:
      return MULTISEND_SFUEL_FEE[chainId]
    default:
      return MULTISEND_SRK_FEE[chainId]
  }
}

export const getMultiSendFee = (feeToken, chainId = ChainId.MAINNET) => {
  if (!feeToken) {
    return multisendFees[chainId].eth
  }
  switch (feeToken) {
    case MULTISEND_SRK_FEE[chainId ?? ChainId.MAINNET]:
      return multisendFees[chainId].srkb
    default:
      return multisendFees[chainId][chainId !== ChainId.MAINNET && chainId !== ChainId.BSCTESTNET ? 'srk' : 'sfuel']
  }
}
