import React from 'react'
import { Flex, Heading, Modal, Text } from '@sparkpointio/sparkswap-uikit'
import styled from 'styled-components'
import { SwapButton } from 'components/swap/styleds'
import { FaInfoCircle } from 'react-icons/fa'
import { dexTheme } from 'ThemeContext'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  margin-top: -30px;
  max-width: 500px;
`

const StyledFooter = styled(Flex)`
  // max-width: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const StyledInfoIcon = styled(FaInfoCircle)`
  vertical-align: middle;
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
  margin: 0 10px;
`

const HighlightText = styled(Text)`
  display: inline-block;
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
`

interface Props {
  onClose: () => void
}

const FirstTimeUserPopup: React.FC<Props> = ({ onClose }) => {
  return (
    <>
      <Modal title="" onDismiss={onClose} bodyPadding="25px" background="#15151A" border="2px solid #39BEEC">
        <ModalContainer>
          <Heading style={{ textAlign: 'center' }}>Welcome to SparkSwap Multisender!</Heading>
          <StyledFooter mt="26px" mb="26px">
            <Flex flexDirection="column" style={{ rowGap: '10px' }}>
              <Text style={{ textAlign: 'center' }}>
                {' '}
                As a first-time visitor, we want to let you know about our exclusive discounts for using certain fee
                tokens on our platform.
              </Text>
              <Text style={{ textAlign: 'center' }}>
                By selecting <HighlightText>SFUEL</HighlightText> as the fee token, you can receive up to{' '}
                <HighlightText>40% off</HighlightText> the platform fee on your transactions. Alternatively, selecting
                SRKb as the fee token can get you a discount of 20% off compared to using BNB as the fee token. So what
                are you waiting for? Select SFUEL or SRKb as your fee token to redeem your discount now!
              </Text>
            </Flex>
          </StyledFooter>
          <SwapButton onClick={onClose}>Let&apos;s Multisend</SwapButton>
          <Text style={{ textAlign: 'center' }} color="textSubtle" mt="26px" fontSize="12px">
            <StyledInfoIcon />
            Please note that the percentage of discount mentioned is subject to fluctuation based on the current market
            price of the tokens at the time of the transaction.{' '}
          </Text>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default FirstTimeUserPopup
