import { ChainId, Currency, ETHER, Token } from '@sparkpointio/sparkswap-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'
import { getChainTokenLogo } from '../../utils'

const getTokenLogoURL = (address: string) => {
  if (address === '0xC3440c10c4F36f354eB591B19FafB4906d449B75') return `${process.env.PUBLIC_URL}/images/coins/SRK.png` // SRKb
  if (address === '0x14b1166aB53A237c8cEaeE2BBc4BbCa200cb7da8') return `${process.env.PUBLIC_URL}/images/coins/SRK.png` // bSRK

  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  chainId = ChainId.MAINNET,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
  chainId?: ChainId
}) {
  const ether = Currency.getEther(chainId)
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const srcs: string[] = useMemo(() => {
    if (_.isEqual(currency, ether)) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          ...uriLocations,
          `${process.env.PUBLIC_URL}/images/coins/${currency?.symbol ?? 'default'}.png`,
          getTokenLogoURL(currency.address),
        ]
      }
      return [
        ...uriLocations,
        `${process.env.PUBLIC_URL}/images/coins/${currency?.symbol ?? 'default'}.png`,
        getTokenLogoURL(currency.address),
      ]
    }
    return []
  }, [currency, uriLocations, ether])

  if (_.isEqual(currency, ether)) {
    return <StyledEthereumLogo src={getChainTokenLogo(chainId)} size={size} style={style} />
  }

  return (currency as any)?.symbol ? (
    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
