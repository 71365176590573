import React, { Dispatch, useContext, useEffect, useState } from 'react'
import { Button, Flex, Modal, Text } from '@sparkpointio/sparkswap-uikit'
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa'
import CodeMirror from 'react-codemirror'
import { StyledContainer, Count } from 'pages/Multisender/styled'
import 'codemirror/mode/sql/sql'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/sql-hint'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/hint/show-hint.css'
import ReactDOMServer from 'react-dom/server'
import Editor from './Editor'
import { innerReducer, initialState } from '../../hooks/slippageController'

type SampleCsvModalProps = {
  onDismiss?: () => void
  action?: Dispatch<{ type: string }>
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  margin-top: -30px;
`

const StyledFooter = styled(Flex)`
  // max-width: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const StyledInfoIcon = styled(FaInfoCircle)`
  vertical-align: middle;
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
  margin: 0 10px;
`

const AddressDataField = styled.textarea`
  box-shadow: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
  border-radius: 6px;
  min-height: 150px;
  max-height: 250px;
  resize: vertical;
  display: block;
  line-height: 30px;
  font-size: 16px;
  width: 100%;
  min-width: 600px;
  // height: 100px;
  padding: 0 16px;
  white-space: pre-line;
`

const StyledCodeMirror = styled(CodeMirror)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
`

const SampleDataText = styled(Text)`
  font-size: auto;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

const CustomCount = styled(Count)`
  font-size: auto;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

const defaultOnDismiss = () => null

const SampleCsvModal = ({ onDismiss = defaultOnDismiss, action }: SampleCsvModalProps) => {
  const SampleData = [
    {
      address: '0x373233a38ae21cf0c4f9de11570e7d5aa6824a1e',
      amount: 145,
    },
    {
      address: '0x8A672715e042f6e9d9B25C2ce9F84210e8206EF1',
      amount: 1.069,
    },
    {
      address: '0xC4515C02c334155bc60d86BD6F1119f58ea136e2',
      amount: 10.81,
    },
    {
      address: '0xe270bc73d658cbd72f721cb8c649aebf91b98d2b',
      amount: 0.058,
    },
  ]

  const [state, dispatch] = React.useReducer(innerReducer, initialState)
  const [text, setText] = useState('')
  // Split data
  // const [data, setData] = useState(SampleData)

  return (
    <Modal title="Example CSV" onDismiss={onDismiss} bodyPadding="15px" background="#15151A" border="2px solid #39BEEC">
      <ModalContainer>
        <StyledFooter mt="6px">
          <Flex flexDirection="column">
            <StyledContainer style={{ alignSelf: 'center' }}>
              {/* Readonly data */}
              {SampleData &&
                Object.values(SampleData).map((address, amount) => {
                  const lineNumber = amount + 1
                  const data = Object.values(address).join(', ')

                  return (
                    <div key={lineNumber} className="csv-data container">
                      <CustomCount>{lineNumber}</CustomCount>
                      <div className="csv-data details">
                        <SampleDataText color="textSubtle">{data}</SampleDataText>
                      </div>
                    </div>
                  )
                })}
            </StyledContainer>
          </Flex>
        </StyledFooter>
        <Text color="textSubtle" mt="8px" fontSize="12px">
          <StyledInfoIcon />
          Note: You can try out the tool by using test tokens first.
        </Text>
      </ModalContainer>
    </Modal>
  )
}

export default SampleCsvModal
