import { useEffect, useRef, useState } from 'react'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import web3NoAccount from 'utils/web3'
import { provider } from 'web3-core'
import { useActiveWeb3React } from './index'

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the provider change
 */
const useWeb3 = () => {
  const { library } = useActiveWeb3React()
  const refEth = useRef(library)
  const [web3, setweb3] = useState(library ? new Web3(library.provider as unknown as provider) : web3NoAccount)

  useEffect(() => {
    if (library !== refEth.current) {
      setweb3(library ? new Web3(library.provider as unknown as provider) : web3NoAccount)
      refEth.current = library
    }
  }, [library])

  return web3
}

export default useWeb3
