import { ChainId, Currency, CurrencyAmount, ETHER, Token, TokenAmount } from '@sparkpointio/sparkswap-sdk'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Flex, Radio, Text, useModal } from '@sparkpointio/sparkswap-uikit'
import { FaExpandAlt } from 'react-icons/fa'
import styled from 'styled-components'
import DefaultCurrencyInputPanel from 'components/DefaultCurrencyInputPanel'
import { RowBetween } from 'components/Row'
import SampleCsvModal from 'components/MultisenderModals/SampleCsvModal'
import FirstVisitPopup from 'components/MultisenderModals/FirstVisitPopup'
import ApproveModal from 'components/MultisenderModals/ApproveModal'
import {
  BottomGrouping,
  CustomStyleCard,
  StyledCardBody,
  StyledSwapButtonGroup,
  SwapButton,
} from 'components/swap/styleds'
import usePrevious from 'hooks/usePrevious'
import Editor from 'components/MultisenderModals/Editor'
import TokenWarningModal from 'components/TokenWarningModal'
import { MULTISEND_ADDRESS, MULTISEND_SFUEL_FEE, MULTISEND_SRK_FEE } from 'constants/index'
import useCsvData from 'hooks/useCsvData'
import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from 'state/swap/hooks'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import ConnectWalletButton from 'components/ConnectWalletButton'
import StepProgress from 'components/StepProgress'
import AppBody from '../AppBody'
import './styled.scss'
import { StyledLink } from './styled'
import { getDecimalAmount, toBigNumber } from '../../utils/formatBalance'
import { useTokenList } from '../../state/lists/hooks'
import { multisendFees } from '../../constants/multisend'
import { getChainTokenDets, getMultiSendFee } from '../../utils'
import { getDefaultFee } from '../../utils/multisend'
import { useIsSubscribed } from '../../hooks/useMultisend'

interface MultisenderProps {
  onClose: () => void
}

const ActionWrapper = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 30px;
  margin-top: 4px;
`

const StyledFooter = styled(Flex)`
  // max-width: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const StyledOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  column-gap: 10px;
  @media (max-width: 500px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const Option = styled.div`
  padding: 0;
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    align-items: space-between;
    justify-content: flex-start;
    width: 100%;
    margin: 5px;
  }
`

const AddressDataField = styled.textarea`
  box-shadow: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
  border-radius: 6px;
  min-height: 100px;
  max-height: 250px;
  resize: vertical;
  display: block;
  line-height: 40px;
  font-size: 16px;
  width: 100%;
  height: 100px;
  padding: 0 16px;
  white-space: pre-line;
`

const ProgressDiv = styled(Flex)`
  margin: 0rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0rem 0rem 2rem 0rem;
  }
`

const StyledActionText = styled(Text)`
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
  font-size: 14px;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
`

const CsvHeaderText = styled(Text)`
  font-size: 14px;
`

const SampleCsvText = styled(Text)`
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
  cursor: pointer;
  font-size: 14px;
`

const SubtleText = styled(Text)<{ isSubscribed: boolean }>`
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 14px;
  margin-left: 10px;
  ${({ isSubscribed }) => (isSubscribed ? 'text-decoration: line-through;' : '')}
`

const TokenFeeWrapper = styled(Flex)`
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  column-gap: 10px;
  @media (max-width: 500px) {
    flex-direction: column;
    column-gap: auto;
  }
`

const FeeTokenHeading = styled(Text)`
  font-size: 14px;
  margin-top: 10px;
  display: flex;
`

const Multisender: React.FC<MultisenderProps> = () => {
  const { account, chainId } = useActiveWeb3React()
  const tokenList = useTokenList('sparkswap')[chainId ?? ChainId.MAINNET]
  const loadedUrlParams = useDefaultsFromURLSearch()
  const chainTokenDetails = getChainTokenDets(chainId)

  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [tokenSent, setTokenSent] = useState(false)
  const [sendCurrency, setSendCurrency] = useState(Currency.getEther(chainId ?? ChainId.MAINNET) as Token)
  const editorRef = useRef<any>()
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]
        ?.filter((c): c is Token => {
          return c instanceof Token
        })
        .filter((c) => {
          return c.symbol !== 'SFUEL' && c.symbol !== 'SRKb'
        }) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )

  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const multisendContract = MULTISEND_ADDRESS[chainId ?? ChainId.MAINNET]

  const { currencyBalances, currencies, inputError: swapInputError } = useDerivedSwapInfo()

  const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setSendCurrency(inputCurrency)
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const allFees = multisendFees[chainId ?? ChainId.MAINNET]
  const sfuelExists = 'sfuel' in allFees
  const totalAmtToSend = useRef(new TokenAmount(sendCurrency, '0'))
  const [feeToken, setFeeToken] = useState(getDefaultFee(chainId) as unknown as undefined)
  const feeTokenAmt = useRef(
    new TokenAmount(
      tokenList[feeToken ?? ''] ?? (ETHER as Token),
      getDecimalAmount('sfuel' in allFees ? allFees.sfuel : allFees.srkb).toString()
    )
  )
  const handleOnSetFeeToken = useCallback(
    (value) => {
      setFeeToken(value)
      const multisendFee = getMultiSendFee(value, chainId)
      feeTokenAmt.current = new TokenAmount(
        tokenList[value ?? ''] ?? (ETHER as Token),
        getDecimalAmount(multisendFee).toString()
      )
    },
    [feeTokenAmt, tokenList, chainId]
  )

  const [expandCsvContainer, setExpandCsvContainer] = useState<boolean>(false)
  const { csvData, setCsvData } = useCsvData()
  const [file, setFile] = useState()
  const previousFile = usePrevious(file)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleUploadFile = (e) => {
    e.preventDefault()
    setFile(e.target.files[0])
  }

  const calculateTotalAmtToSend = useCallback(() => {
    let totalAmount = toBigNumber('0')
    try {
      csvData.forEach((item, ind) => {
        const counter = ind + 1
        const amount = item[1] ?? '0'
        totalAmount = totalAmount.plus(toBigNumber(amount ?? '0'))
        if (counter === csvData.length) {
          totalAmtToSend.current = new TokenAmount(
            sendCurrency,
            getDecimalAmount(totalAmount, sendCurrency.decimals).toString()
          )
        }
      })
    } catch (e) {
      console.error(e)
    }
  }, [csvData, sendCurrency])

  const [showModal, setShowModal] = useState<boolean>(false)
  const isSubscribed = useIsSubscribed()

  useEffect(() => {
    document.title = 'Multisender | SparkSwap'
    const isReturningVisitor = localStorage.getItem('isReturningVisitor') === 'true'
    if (!isReturningVisitor) {
      setShowModal(true)
      localStorage.setItem('isReturningVisitor', 'true')
    }
    setSendCurrency(Currency.getEther(chainId ?? ChainId.MAINNET) as Token)
  }, [chainId])

  const [onSampleCsvModal] = useModal(<SampleCsvModal />)
  const [onApproveModal] = useModal(
    <ApproveModal
      csvData={csvData}
      options={{
        sendCurrency,
        account,
        chainId,
        multisendContract,
        totalAmtToSend,
        feeToken,
        tokenList,
        feeTokenAmt,
        editorRef,
        isSubscribed,
        setTokenSent,
      }}
    />,
    false
  )

  const TokenFee = () => {
    return (
      <TokenFeeWrapper>
        <FeeTokenHeading>Fee token: </FeeTokenHeading>
        <StyledOptions>
          {sfuelExists && (
            <Option key="0">
              <Radio
                scale="sm"
                name="feeToken"
                onClick={(e) =>
                  handleOnSetFeeToken(MULTISEND_SFUEL_FEE[chainId ?? ChainId.MAINNET] as unknown as undefined)
                }
                defaultChecked={sfuelExists}
                checked={isSubscribed ? false : undefined}
                disabled={isSubscribed}
              />
              <SubtleText isSubscribed={isSubscribed}>
                {sfuelExists ? allFees['sfuel'].toString() : ''}{' '}
                <StyledLink
                  title="Get SFUEL"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sparkswap.finance/#/swap?outputCurrency=0x37ac4d6140e54304d77437a5c11924f61a2d976f"
                >
                  SFUEL
                </StyledLink>
              </SubtleText>
            </Option>
          )}
          <Option key="1">
            <Radio
              scale="sm"
              name="feeToken"
              onClick={(e) =>
                handleOnSetFeeToken(MULTISEND_SRK_FEE[chainId ?? ChainId.MAINNET] as unknown as undefined)
              }
              defaultChecked={!sfuelExists}
              checked={isSubscribed ? false : undefined}
              disabled={isSubscribed}
            />
            <SubtleText isSubscribed={isSubscribed}>
              {allFees.srkb.toString()} {sfuelExists ? 'SRKb' : 'SRK'}
            </SubtleText>
          </Option>

          <Option key="2">
            <Radio
              scale="sm"
              name="feeToken"
              onClick={(e) => handleOnSetFeeToken(undefined)}
              checked={isSubscribed ? false : undefined}
              disabled={isSubscribed}
            />
            <SubtleText isSubscribed={isSubscribed}>
              {allFees.eth.toString()} {chainTokenDetails.symbol}
            </SubtleText>
          </Option>
        </StyledOptions>
      </TokenFeeWrapper>
    )
  }

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      {showModal ? (
        <FirstVisitPopup onClose={() => setShowModal(false)} />
      ) : (
        <AppBody>
          <ProgressDiv justifyContent="center">
            <StepProgress
              dataIsValid={editorRef?.current?.getErrors().errorLines.length === 0}
              dataIsEmpty={csvData.length === 0}
              tokensSent={tokenSent}
              setTokenSent={setTokenSent}
            />
          </ProgressDiv>

          <CustomStyleCard>
            <StyledCardBody>
              <div className="app-container">
                <DefaultCurrencyInputPanel
                  label="Token Address"
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="token-currency-input"
                />

                <RowBetween>
                  <CsvHeaderText>List of Addresses in CSV</CsvHeaderText>
                  {/* Link to Sample CSV  */}
                  <SampleCsvText onClick={onSampleCsvModal}>Show Sample CSV</SampleCsvText>
                </RowBetween>

                <Editor
                  ref={editorRef}
                  file={file}
                  setCsvData={setCsvData}
                  csvData={csvData}
                  sendCurrency={sendCurrency}
                  previousFile={previousFile}
                  expand={expandCsvContainer}
                />
                <ActionWrapper>
                  {TokenFee()}
                  <Button
                    variant="text"
                    onClick={() => {
                      if (inputRef.current !== null) {
                        inputRef.current.click()
                      }
                    }}
                  >
                    <StyledActionText>Upload CSV</StyledActionText>
                  </Button>
                  <input
                    ref={inputRef}
                    onChange={(e) => {
                      handleUploadFile(e)
                      if (inputRef.current !== null) {
                        inputRef.current.value = ''
                      }
                    }}
                    type="file"
                    style={{ display: 'none' }}
                    placeholder="test"
                  />
                  <StyledActionText mt="14px"> | </StyledActionText>
                  <Button variant="text" onClick={() => setExpandCsvContainer(!expandCsvContainer)}>
                    <StyledActionText>
                      <FaExpandAlt style={{ verticalAlign: 'middle' }} />
                    </StyledActionText>
                  </Button>
                </ActionWrapper>
              </div>
              <BottomGrouping>
                <StyledSwapButtonGroup>
                  {!account ? (
                    <ConnectWalletButton fullWidth />
                  ) : (
                    <SwapButton
                      fullWidth
                      disabled={csvData.length === 0}
                      onClick={() => {
                        if (editorRef?.current?.getErrors().errorLines.length === 0) {
                          calculateTotalAmtToSend()
                          onApproveModal()
                        } else {
                          editorRef?.current?.goToError()
                        }
                      }}
                    >
                      {editorRef?.current?.getErrors().errorLines.length === 0 ? 'Continue' : 'Go to error'}
                    </SwapButton>
                  )}
                </StyledSwapButtonGroup>
              </BottomGrouping>
            </StyledCardBody>
          </CustomStyleCard>
        </AppBody>
      )}
    </>
  )
}

export default Multisender
