import BigNumber from 'bignumber.js'
import { ChainId, ETHER, JSBI, Percent, Token, WETH } from '@sparkpointio/sparkswap-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { bsc, injected, walletconnect } from '../connectors'
import ROUTER_ABI from './router/abi.json'
import SPARKREWARD_ABI from './abis/erc1967Proxy.json'

export declare enum ChainIdBSC {
  MAINNET = ChainId.MAINNET,
  BSCTESTNET = ChainId.BSCTESTNET,
}
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const ROUTER_ADDRESS = '0xeB98E6e5D34c94F56708133579abB8a6A2aC2F26'
const SPARKREWARD_ADDRESS = '0x9E2B8733fc49dCFf101f36050DA099cCcc90F930'

export const MAINNET_CHAIN_ID = '56'

const MULTISEND_ADDRESS = {
  '1': '0x80e3807daA804125aD06f1Ecf4F8D59dcE7da39c',
  '5': '0x80e3807daA804125aD06f1Ecf4F8D59dcE7da39c',
  [ChainId.MAINNET]: '0x80e3807daA804125aD06f1Ecf4F8D59dcE7da39c',
  [ChainId.BSCTESTNET]: '0x80e3807daA804125aD06f1Ecf4F8D59dcE7da39c',
}

export const MULTISEND_SRK_FEE: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xC3440c10c4F36f354eB591B19FafB4906d449B75',
  [ChainId.BSCTESTNET]: '0xe09B8661D80CF24dB230A167969d18B94a5a3266',
  [ChainId.ETHMAINNET]: '0x0488401c3F535193Fa8Df029d9fFe615A06E74E6',
  [ChainId.ETHTESTNET]: '0xb8080B8B6BAffadb5DC50f9D31Dc126f2b99c7aA',
}

export const MULTISEND_SFUEL_FEE: { [chainId in ChainIdBSC]: string } = {
  [ChainId.MAINNET]: '0x37Ac4D6140e54304D77437A5c11924f61a2D976f',
  [ChainId.BSCTESTNET]: '0x17DfDF426b7E994FB82855E052e3c2D4248ff496',
}

export { ROUTER_ABI, ROUTER_ADDRESS, SPARKREWARD_ADDRESS, SPARKREWARD_ABI, MULTISEND_ADDRESS }

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// const data = [["0xcE84500ad8411b14e5DA048adCdF46284660a0AA","100000000000000000000"],["0x221Ea53305e826DD03Cc4D2b763b526cb33243A9","200000000000000000000"]]
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD')
export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 8, 'WBNB', 'Wrapped BNB')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const EOS = new Token(ChainId.MAINNET, '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6', 18, 'EOS', 'EOS Token')
export const DOT = new Token(ChainId.MAINNET, '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402', 18, 'DOT', 'Polkadot Token')
export const ETH = new Token(ChainId.MAINNET, '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18, 'ETH', 'Ethereum Token')

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
  [ChainId.ETHMAINNET]: [WETH[ChainId.ETHMAINNET]],
  [ChainId.ETHTESTNET]: [WETH[ChainId.ETHTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: {
  [ChainId.MAINNET]: Token[]
  [ChainId.ETHMAINNET]: Token[]
  [ChainId.BSCTESTNET]: Token[]
} = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, EOS, DOT],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainIdBSC]: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [ETH.address]: [DAI, WETH[ChainId.MAINNET]],
  },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: {
  [ChainId.MAINNET]: Token[]
  [ChainId.ETHMAINNET]: Token[]
  [ChainId.BSCTESTNET]: Token[]
} = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: {
  [ChainId.MAINNET]: Token[]
  [ChainId.ETHMAINNET]: Token[]
  [ChainId.BSCTESTNET]: Token[]
} = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

export const PINNED_PAIRS: { readonly [chainId in ChainIdBSC]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0xC3440c10c4F36f354eB591B19FafB4906d449B75', 18, 'SRKb', 'SparkPoint Token'),
      new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [BUSD, USDT],
    [DAI, USDT],
  ],
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  BSC: {
    connector: bsc,
    name: 'Binance Chain Wallet',
    iconName: 'binance.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}
export const MAINNET_ETH_CHAIN_ID = '1'

export const BASE_BSC_SCAN_URL = 'https://bscscan.com'

export const supportedChains = {
  MULTISENDER: ['56', '97', '1', '5'],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
