import { Text, Button, IconButton } from '@sparkpointio/sparkswap-uikit'
import styled from 'styled-components'

export const Count = styled(Text)`
  background-color: #013d68;
  min-width: 50px;
  text-align: center;
  font-size: 16px;
`
export const UploadCsvBtn = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
`

export const ExpandBtn = styled(IconButton)`
  color: ${({ theme }) => theme.dexTheme.colors.accent1};
  border: none;
  width: 60px;
`

export const StyledContainer = styled.div<{ height?: string }>`
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
  border-radius: 6px;
  padding-right: 10px;
  height: ${({ height }) => height ?? 'auto'}};
  overflow: auto;
  margin-bottom: 10px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  // margin-bottom: 30px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:visited {
    color: pink;
  }

  &:active {
    color: white;
  }
`
