import React from 'react'
import { Button, Flex, Heading } from '@sparkpointio/sparkswap-uikit'
import { switchNetwork } from 'utils/wallet'
import styled from 'styled-components'
import { MAINNET_CHAIN_ID } from '../constants'
import AppBody from './AppBody'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  @media (max-width: 500px) {
    margin: 0 30px;
  }
`

const StyledHeading = styled(Heading)`
  margin: 10px;
  text-align: center;
`

const NotSupported: React.FC<{ supportedChainId?: number }> = ({ supportedChainId = MAINNET_CHAIN_ID }) => {
  return (
    <AppBody>
      <StyledNotFound>
        <img src="/SparkSwapLogo.png" alt="SparkSwapLogoIcon" style={{ marginBottom: '8px', width: '140px' }} />
        <Flex flexDirection="column" marginBottom="1em">
          <StyledHeading>The Network you are using is currently not supported</StyledHeading>
        </Flex>
        <Button
          onClick={async () => {
            await switchNetwork(supportedChainId)
          }}
          size="sm"
        >
          Switch to supported network
        </Button>
      </StyledNotFound>
    </AppBody>
  )
}

export default NotSupported
