import React, { Dispatch } from 'react'
import { Button, Flex, Modal, Text } from '@sparkpointio/sparkswap-uikit'
import styled from 'styled-components'
import { StyledContainer } from 'pages/Multisender/styled'
import 'codemirror/mode/sql/sql'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/sql-hint'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/hint/show-hint.css'

import { CSVLink } from 'react-csv'
import moment from 'moment'

type TxSummaryModalProps = {
  data: {
    txHash?: string
    totalSentTokens?: string
    platformFee?: string
    totalRecipients?: number
    sendTokenBalance?: string
    feeTokenBalance?: string
    sendToken?: string
    recipients?: any
  }
  onDismiss?: () => void
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  margin-top: -30px;
`

const StyledFooter = styled(Flex)`
  // max-width: 200px;
  justify-content: center;
  width: 100%;
  align-items: center;
`

const TxSummaryDataText = styled(Text)`
  font-size: auto;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

const defaultOnDismiss = () => null

const TxSummaryModal = ({ onDismiss, data }: TxSummaryModalProps) => {
  const { recipients } = data
  const csvData = [
    ['Multisend Details'],
    ['Transaction Hash', data.txHash],
    ['Total Sent Tokens', data.totalSentTokens],
    ['Platform Fee', data.platformFee],
    ['Total Recipients', data.totalRecipients],
    [''],
    ['Sender Details'],
    ['Send token balance', data.sendTokenBalance],
    ['Fee token balance', data.feeTokenBalance],
    [''],
    ['Recipients', data.sendToken],
  ]

  csvData.push(...recipients)

  const currentDate = moment().format('MM-DD-YY hmm').toString()
  return (
    <Modal
      title="Transaction Details"
      onDismiss={onDismiss}
      bodyPadding="15px"
      background="#15151A"
      border="2px solid #39BEEC"
    >
      <ModalContainer>
        <StyledFooter mt="6px">
          <Flex flexDirection="column">
            <StyledContainer style={{ alignSelf: 'center' }}>
              {/* Readonly data */}
              {csvData &&
                Object.values(csvData).map((address, amount) => {
                  const lineNumber = amount + 1
                  const d = Object.values(address).join(', ')

                  return (
                    <div key={lineNumber} className="csv-data container">
                      {/* <CustomCount>{lineNumber}</CustomCount> */}
                      <div className="csv-data details">
                        <TxSummaryDataText color="textSubtle">{d}</TxSummaryDataText>
                      </div>
                    </div>
                  )
                })}
            </StyledContainer>
          </Flex>
        </StyledFooter>
        <CSVLink filename={`Multisend Details - ${currentDate}`} data={csvData}>
          <Button variant="primary">Download CSV</Button>
        </CSVLink>
      </ModalContainer>
    </Modal>
  )
}

export default TxSummaryModal
