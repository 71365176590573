import React, { createContext, useEffect, useState, Dispatch } from 'react'

interface CsvFileContext {
  csvData: Array<string[]>
  setCsvData: (state: string[][]) => void
}

const CsvFileContext = createContext<CsvFileContext>({ csvData: [], setCsvData: (state: string[][]) => null })

const CsvFileProvider: React.FC = ({ children }) => {
  const [csvData, setCsvData] = useState<Array<string[]>>([])
  return <CsvFileContext.Provider value={{ csvData, setCsvData }}>{children}</CsvFileContext.Provider>
}

export { CsvFileContext, CsvFileProvider }
