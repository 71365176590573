import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@medardm/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { BscConnector } from './bsc/bscConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const ETH_NETWORK_URL = process.env.REACT_APP_ETH_NODE_1
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
const bscMain = parseInt('56', 10)
const ethMain = parseInt('1', 10)
const bscTestnet = parseInt('97', 10)
const goerli = parseInt('5', 10)

if (typeof NETWORK_URL === 'undefined' || typeof ETH_NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL and REACT_APP_ETH_NODE_1 must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: Web3Provider | undefined

export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [bscMain, bscTestnet, ethMain, goerli],
})

export const bsc = new BscConnector({
  supportedChainIds: [bscMain, bscTestnet],
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
  chains: [56],
  optionalChains: [1, 97],
  showQrModal: true,
  metadata: {
    name: 'SparkSwap Multisender',
    description:
      'Send tokens to thousands of addresses in just a few seconds. Use your SRKb and SFUEL tokens for discounted rates.',
    url: 'multisend.sparkswap.finance',
    icons: [],
  },
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
})
