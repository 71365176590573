import React, { useState, useCallback, useContext } from 'react'
import { Currency, Pair, Token, ETHER, CurrencyAmount, ChainId } from '@sparkpointio/sparkswap-sdk'
import { Button, Dropdown, Text, Flex } from '@sparkpointio/sparkswap-uikit'
import { ChevronDownIcon, ChevronUpIcon } from 'components/PositionCard/CustomChevron'
import styled, { ThemeContext } from 'styled-components'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import ReactLoading from 'react-loading'
import { darken } from 'polished'
import { SwapButton } from 'components/swap/styleds'
import { getAddress } from 'ethers/lib/utils'
import _ from 'lodash'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { Input as LiquidityNumericalInput } from '../LiquidityNumericalInput'
import { useActiveWeb3React } from '../../hooks'
import TranslatedText from '../TranslatedText'
import { TranslateString } from '../../utils/translateTextHelpers'
import { getChainTokenDets } from '../../utils'

function getCurrencyAddress(currency: Currency, chainId: ChainId = ChainId.MAINNET): string {
  const ether = Currency.getEther(chainId)
  return currency instanceof Token
    ? currency.address
    : _.isEqual(currency, ether)
    ? getChainTokenDets(chainId).name
    : ''
}

const InputRow = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 0.75rem')};
`

const StyledUnderline = styled.div`
  border-bottom: 4px solid ${({ theme }) => theme.dexTheme.colors.accent1};
  width: 59%;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0;

  :focus,
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.input)};
  }
`

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  // padding: 0.75rem 1rem 0 1rem;
  padding: 0.75rem 1rem 1rem 0;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  // border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  // background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
  margin-bottom: 30px;
`

const Container = styled.div<{ hideInput: boolean }>`
  // border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  border: solid 1px ${({ theme }) => theme.dexTheme.colors.accent2};
  border-radius: 6px;
  // padding: 10px 16px 8px 0;
  // height: 100px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
`

const StyledCurrencyLogo = styled(CurrencyLogo)`
  margin-right: 34px;
`

const UnderlineDiv = styled(Flex)`
  justify-content: center;
`

const CurrencyPlaceHolder = styled(Text)`
  color: ${({ theme }) => theme.colors.textSubtle};
  text-align: left;
`

const CurrencyAddressText = styled(Text)`
  color: ${({ theme }) => theme.colors.textSubtle};
  margin-top: 4px;
  @media (max-width: 536px) {
    margin-top: auto;
  }
`

interface DefaultCurrencyInputPanelProps {
  value?: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton?: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}

export default function DefaultCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(132, 'Input'),
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
}: DefaultCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useContext(ThemeContext)
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const muitheme = useTheme()
  const largeScreen = useMediaQuery(muitheme.breakpoints.up('sm'))
  return (
    <InputPanel id={id}>
      <Text fontSize="14px">{label}</Text>
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
            style={{ width: '100%' }}
          >
            {/* <CurrencyPlaceHolder>Select name or paste address here</CurrencyPlaceHolder> */}
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <Flex>
                  <CurrencyLogo currency={currency} chainId={chainId} size="34px" style={{ marginRight: '14px' }} />
                  <Text mr="12px" mt="4px" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length
                        )}`
                      : currency?.symbol) || <TranslatedText translationId={82}>Select a token</TranslatedText>}
                  </Text>
                  &nbsp;
                  {largeScreen && <CurrencyAddressText>{getCurrencyAddress(currency, chainId)}</CurrencyAddressText>}
                </Flex>
              ) : null}

              {/* {pair ? (
                <Text>
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length
                      )}`
                    : currency?.symbol) || <TranslatedText translationId={82}>Select a token</TranslatedText>}
                </Text>
              )} */}
              <div style={{ margin: '0 10px' }}>{/* {!disableCurrencySelect && <ChevronDownIcon />} */}</div>
            </Aligner>
          </CurrencySelect>

          {/* {!hideInput && (
            <>
              <LiquidityNumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <SwapButton onClick={onMax} size="sm" variant="primary" style={{maxWidth: '50px', width: '20%', fontSize: '14px'}}>
                  MAX
                </SwapButton>
              )}
            </>
          )} */}
        </InputRow>
        {/* <UnderlineDiv>
          <StyledUnderline/>
        </UnderlineDiv> */}
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
      {/* {!hideInput && (
        <LabelRow>
          <RowBetween>
            {account && (
              <Text onClick={onMax} fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? `Available: ${selectedCurrencyBalance?.toSignificant(6)} ${currency.symbol}`
                  : <ReactLoading type='bubbles' color={theme.colors.text} width='21px' height='21px'/>}
              </Text>
            )}
          </RowBetween>

        </LabelRow>
      )} */}
    </InputPanel>
  )
}
