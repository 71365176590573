import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Flex, IconButton, Text } from '@sparkpointio/sparkswap-uikit'
import { AlertCircle, AlertTriangle, Box, ChevronRight, CornerDownRight, Maximize, Minimize } from 'react-feather'
import { Controlled as ControlledEditorComponent } from 'react-codemirror2'
import { checkDuplicate, mergeDuplicates } from 'utils/dataCleaner'
import useTheme from 'hooks/useTheme'
import { short_address } from 'utils/wallet'
import Papa from 'papaparse'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/mdn-like.css'
import 'codemirror/theme/night.css'
import 'codemirror/theme/the-matrix.css'
import 'codemirror/mode/css/css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/xml/xml'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/selection/active-line'
import './style.css'
import { isAddress } from '../../utils'
import { toBigNumber } from '../../utils/formatBalance'

// { language, value, setEditorState }

interface EditorProps {
  sendCurrency?: any
  file: File | undefined
  previousFile: File | undefined
  setCsvData: any
  csvData: any
  expand?: boolean
}

const Editor = forwardRef(({ expand, file, previousFile, setCsvData, csvData, sendCurrency }: EditorProps, ref) => {
  const { theme } = useTheme()
  const [prevWarnIndex, setPrevWarnIndex] = useState<any>(0)
  const [editorObj, setEditorObj] = useState<any>()
  const [dataRows, setDataRows] = useState<string>('')
  const [expandErrorCont, setExpandErrorCont] = useState<boolean>(false)
  const [dirtyRows, setDirtyRows] = useState<any>([])
  const [warningRows, setWarningRows] = useState<any>([])
  const lineErrors = useRef<any[]>([])
  const warnings = useRef<{ [key: string]: any[] }>({ lines: [], info: [] })
  const errorLines = useRef<any[]>([])
  useImperativeHandle(ref, () => ({
    getErrors() {
      return { errorLines: errorLines.current, lineErrors: lineErrors.current }
    },
    clear() {
      editorObj.setValue('')
      editorObj.clearHistory()
    },
    goToError() {
      if (errorLines.current.length > 0) {
        const nextErr = errorLines.current[0]
        editorObj?.setCursor({ line: nextErr, ch: 0 })
        return nextErr
      }
      return false
    },
  }))

  const onClick = () => {
    setExpandErrorCont(!expandErrorCont)
  }

  const markLine = (line, type = 'error') => {
    switch (type) {
      case 'duplicate':
        editorObj?.addLineClass(line, 'textClass', 'duplicate')
        // editorObj?.removeLineClass(line, 'textClass', 'error')
        break
      case 'error':
        editorObj?.addLineClass(line, 'textClass', 'error')
        editorObj?.removeLineClass(line, 'textClass', 'duplicate')
        break
      case 'success':
      default:
        editorObj?.removeLineClass(line, 'textClass', 'error')
        editorObj?.removeLineClass(line, 'textClass', 'duplicate')
        break
    }
  }

  if (file !== previousFile) {
    Papa.parse(file, {
      // header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data.length) {
          setCsvData(result.data)
          setDirtyRows([])
          setWarningRows([])
          const data = result.data
            .map((val, i) => {
              // errors = []
              return `${val.join(', ')}`
            })
            .join('\n')
          setDataRows(data)
          checkLines(result.data)
        }
      },
    })
  }

  const checkLines = (dataArr) => {
    if (dataArr.length) {
      const end = dataArr.length
      // switch (origin) {
      //     case 'paste':
      //     case 'undo':
      //         start = from ?? 0
      //         end = dataArr.length
      //         break;
      //     default:
      //         start = from ?? 0
      //         end = to ?? dataArr.length
      // }
      for (let i = 0; i < end; i++) {
        const row = dataArr[i]
        checkLine(row, i)
        scanDuplicate(row, i, dataArr)
      }
      // const modifiedLines = dataArr.slice(rowData.from.line, rowData.to.line + 1)
      const linesArr: any[] = dataArr.map((x, i) => i.toString())

      warnings.current.lines = resetRefValues(warnings.current.lines, linesArr)
      warnings.current.info = resetRefValues(warnings.current.info, warnings.current.lines)
      errorLines.current = resetRefValues(errorLines.current, linesArr)
      lineErrors.current = resetRefValues(lineErrors.current, errorLines.current)

      displayErrors()
      displayWarnings()
    }
  }

  const resetRefValues = (refValues, checkRefValues) => {
    return refValues.filter((x) => {
      if (typeof x === 'string') {
        if (checkRefValues.indexOf(x) === -1) return null
        return x
      }
      if (checkRefValues.indexOf(Object.keys(x)[0]) === -1) return null
      return x
    })
  }

  const handleMerge = (address, lines, event) => {
    event.preventDefault()
    // for (let i = 1; i <= lines.length; i++) {
    //   editorObj?.replaceRange("", new Pos())
    // }
    // const toMerge = warnings.current.info.filter((x) => {
    //   const ln = Object.keys(x)[0]
    //   return ln.indexOf(lines) > -1
    // })

    // const mergedValue = mergeDuplicates(toMerge)
  }

  const [counter, setCounter] = useState<number>(0)
  const [previousDupArrLines, setPreviousDupArrLines] = useState<string[]>([])

  // const prevGotoLine = usePrevious(warningGotoLines)

  // useEffect(() => {
  //   if (prevGotoLine === warningGotoLines) setCounter(0)
  // }, [prevGotoLine, warningGotoLines])

  // const handleLinesForWarning = (lines) => {
  //   const warningLinesGroup = lines.split(', ')
  //   setWarningGotoLines(warningLinesGroup)
  // }

  const goToWarning = (lines) => {
    const nextWarnIndex = prevWarnIndex + 1
    if (prevWarnIndex < lines.length && nextWarnIndex !== lines.length) {
      setPrevWarnIndex(nextWarnIndex)
    } else {
      setPrevWarnIndex(0)
      editorObj?.setCursor({ line: parseInt(lines[0]), ch: 0 })
    }
    if (lines[prevWarnIndex]) {
      editorObj?.setCursor({ line: parseInt(lines[prevWarnIndex]), ch: 0 })
    }
  }

  const groupDuplicates = (arr) => {
    const result: any[] = []
    const addresses = [
      ...new Set(
        arr.map((x) => {
          const ln = Object.keys(x)[0]
          return x[ln].address
        })
      ),
    ]
    addresses.forEach((x) => {
      const lns = arr
        .filter((y) => {
          const ln = Object.keys(y)[0]
          if (x !== y[ln].address) return null
          return ln
        })
        .map((z) => Object.keys(z)[0])

      result.push({
        [`${x}`]: lns,
      })
    })
    return result
  }

  const scanDuplicate = (row, line, dataArr) => {
    const addr = row[0]
    const amt = row[1]
    const duplicates = checkDuplicate(dataArr?.map((x) => x[0])).filter((x) => x !== '')
    const duplicateAddressArr = [...new Set(duplicates)]
    if (isAddress(addr)) {
      if (duplicateAddressArr.indexOf(addr) > -1) {
        markLine(line, 'duplicate')
        if (warnings.current.lines.indexOf(line.toString()) === -1) {
          warnings.current.lines.push(line.toString())
          warnings.current.info.push({
            [line.toString()]: {
              address: addr,
              amount: amt,
            },
          })
        } else {
          warnings.current.info = warnings.current.info.map((d, i) => {
            const dLine = Object.keys(d)[0]
            if (dLine === line.toString()) {
              d.address = addr
              d.amount = amt
              return d
            }
            return d
          })
        }
      } else {
        warnings.current.lines.sort((a, b) => a - b).splice(line.toString())
        warnings.current.info = warnings.current.info.filter((d) => {
          return warnings.current.lines.indexOf(Object.keys(d)[0]) > -1
        })
      }
    } else {
      warnings.current.lines.sort((a, b) => a - b).splice(line.toString())
      warnings.current.info = warnings.current.info.filter((d) => {
        return warnings.current.lines.indexOf(Object.keys(d)[0]) > -1
      })
    }
  }

  const checkLine = (row, line) => {
    if (
      !row ||
      row.length !== 2 ||
      !isAddress(row[0]) ||
      !row[1] ||
      toBigNumber(row[1])?.decimalPlaces() > sendCurrency.decimals ||
      toBigNumber(row[1])?.isNaN() ||
      toBigNumber(row[1])?.isEqualTo('0')
    ) {
      markLine(line, 'error')
      const addr = row[0]
      const amt = row[1]
      if (errorLines.current.indexOf(line.toString()) === -1) {
        errorLines.current.push(line.toString())
        lineErrors.current.push({
          [line.toString()]: {
            addr: !addr || !isAddress(addr),
            amt: !amt || toBigNumber(amt).isNaN() || toBigNumber(amt).isZero(),
            invalidRow: row.length > 2,
          },
        })
      } else {
        lineErrors.current = lineErrors.current.map((d, i) => {
          if (Object.keys(d)[0] === line.toString()) {
            d.addr = !addr || !isAddress(addr)
            d.amt = !amt || toBigNumber(amt).isNaN() || toBigNumber(amt).isZero()
            d.invalidRow = row.length > 2
            return d
          }
          return d
        })
      }
    } else {
      if (errorLines.current.indexOf(line.toString()) > -1) {
        errorLines.current.splice(errorLines.current.indexOf(line.toString()))
        lineErrors.current = lineErrors.current.filter((d, i) => {
          return Object.keys(d)[0] !== line.toString()
        })
      }
      markLine(line, 'success')
    }
  }

  const handleChange = (editor, data, value) => {
    setDirtyRows([])
    setWarningRows([])
    setDataRows(value)
    const dataArr = value.split('\n').map((d) => d.split(','))
    if (value) {
      checkLines(dataArr)
      setCsvData(dataArr)
    } else {
      setCsvData([])
      lineErrors.current = []
      errorLines.current = []
      markLine(0, 'success')
    }
  }

  const displayErrors = () => {
    const dRs = dirtyRows.filter((ea) => {
      const ln = (parseInt(ea.line) - 1).toString()
      return errorLines.current.indexOf(ln) > -1
    })
    setDirtyRows(dRs)
    if (errorLines.current.length !== 0) {
      lineErrors.current.forEach((lineError) => {
        const line = Object.keys(lineError)[0]
        const lineErr = lineError[line]
        const lineOfRow = (parseInt(line) + 1).toString()
        let errMsg = ''
        if ((lineErr.addr && lineErr.amt) || lineErr.invalidRow) {
          errMsg = 'Please check the values for each column. Check "Sample CSV" for additional details'
        } else if (lineErr.addr) {
          const address = editorObj?.getLine(line)?.split(',')[0]
          const shortAddr = short_address(address, 10)
          errMsg = `Please check the given address "${shortAddr}"`
        } else if (lineErr.amt || lineErr.amt === 0) {
          errMsg = 'Invalid amount'
        }

        return setDirtyRows((prevState) => {
          const prevEntry = prevState.filter((d) => {
            return d.line === lineOfRow
          })

          if (prevEntry.length > 0) {
            return prevState.sort((a, b) => a.line - b.line)
          }
          return [{ line: lineOfRow, errMsg }, ...prevState]
            .filter((s) => errorLines.current.indexOf(s.line))
            .sort((a, b) => a.line - b.line)
        })
      })
    } else {
      setDirtyRows([])
    }
  }

  const displayWarnings = () => {
    // Reset warnings
    setWarningRows([])
    if (warnings.current.info.length !== 0) {
      const duplicateSummary = groupDuplicates(warnings.current.info)
      duplicateSummary.forEach((warning) => {
        const address = Object.keys(warning)[0]
        const lines = warning[address].map((x) => x)
        const shortAddress = short_address(address, 10)
        const warnMsg = `[ ${lines
          .map((x) => (parseInt(x) + 1).toString())
          .join(', ')} ] - found duplicate address: ${shortAddress}`

        return setWarningRows((prevState) => {
          const prevEntry = prevState.filter((d) => Object.keys(d)[0] === address)
          if (prevEntry.length > 0) return prevState
          return [{ address, lines, warnMsg }, ...prevState]
        })
      })
    } else setWarningRows([])
  }
  return (
    <>
      <ControlledEditorComponent
        editorDidMount={(editor) => {
          // editor.showCursorWhenSelecting(true)
          setEditorObj(editor)
        }}
        onBeforeChange={handleChange}
        onChange={handleChange}
        value={dataRows}
        className={`code-mirror-wrapper ${expand && 'expand'}`}
        options={{
          mode: 'csv',
          theme: 'material',
          styleActiveLine: { nonEmpty: true },
          lineNumbers: true,
        }}
      />
      {(dirtyRows.length !== 0 || warningRows.length) !== 0 && (
        <>
          <div
            // role="button"
            // onKeyPress={(e) => {
            //   const enterOrSpace =
            //     e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar' || e.which === 13 || e.which === 32
            //   if (enterOrSpace) {
            //     e.preventDefault()
            //     onClick()
            //   }
            // }}
            // tabIndex={0}
            className="msgs-container"
            style={expandErrorCont ? { height: '100%' } : { height: '150px', width: '100%', overflowY: 'auto' }}
            // onClick={(e) => {e.preventDefault(); onClick()}}
          >
            {dirtyRows.length !== 0 && (
              <div className="error-container">
                <div>
                  <AlertCircle size="2rem" style={{ marginTop: '5px', marginLeft: '1em' }} />
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  {dirtyRows.map((err, ind) => {
                    const key = ind
                    const { line, errMsg } = err
                    return (
                      <p key={key} className="error-text">
                        Line {line} : {errMsg}
                      </p>
                    )
                  })}
                </div>
              </div>
            )}
            {warningRows.length !== 0 && (
              <div className="warning-container">
                {/* <AlertTriangle size="1em" style={{ marginTop: '5px' }} /> */}
                <Flex flexDirection="column" alignItems="flex-start">
                  {warningRows.map((err, ind) => {
                    const key = ind
                    const { address, lines, warnMsg } = err
                    // const { line, warnMsg } = err
                    return (
                      <Flex key={key} justifyContent="space-between" alignItems="center">
                        <Flex>
                          {/* <IconButton */}
                          {/*   title="Merge lines" */}
                          {/*   variant="text" */}
                          {/*   onClick={(e) => handleMerge(address, lines, e)} */}
                          {/*   style={{ borderRadius: '50px' }} */}
                          {/* > */}
                          {/*   <Box color={theme.colors.text} /> */}
                          {/* </IconButton> */}
                          <IconButton
                            title="Go To"
                            variant="text"
                            onClick={() => goToWarning(lines)}
                            style={{ borderRadius: '50px', width: '2rem', margin: '0 1em' }}
                          >
                            <CornerDownRight color={theme.colors.text} />
                          </IconButton>
                        </Flex>
                        <p className="warning-text">
                          {/*     Line {parseInt(line) + 1} : {warnMsg.adr} */}
                          {/* {warnMsg.amt && `, amount: ${warnMsg.amt}`} */}
                          Lines {warnMsg}
                        </p>
                      </Flex>
                    )
                  })}
                </Flex>
              </div>
            )}
          </div>

          <Flex style={{ width: '100%' }} justifyContent="flex-end">
            <IconButton
              title="Toggle size for warning messages"
              style={{ borderRadius: '50px', margin: '0.5em' }}
              variant="text"
              onClick={onClick}
            >
              {' '}
              {expandErrorCont ? <Minimize /> : <Maximize />}{' '}
            </IconButton>
          </Flex>
        </>
      )}
    </>
  )
})

export default Editor
