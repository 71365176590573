import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from '@sparkpointio/sparkswap-uikit'
import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { useWeb3React } from '@web3-react/core'
// import { useActiveWeb3React } from 'hooks'
import useReload from 'hooks/useReload'
import Popups from 'components/Popups'
import ToastListener from 'components/ToastListener'
import { BG } from 'components/swap/styleds'
import { getSupportedChain, isSupportedChain } from 'utils'
// import Send from './Multisender/Send'
import NotSupported from './NotSupported'
import Web3ReactManager from '../components/Web3ReactManager'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
  RedirectToLiquidity,
} from './AddLiquidity/redirects'
import Pool from './Pool'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import PoolFinder from './PoolFinder'
import History from './History'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Multisender from './Multisender'
import Proceed from './Multisender/Proceed'
import Summary from './Multisender/Summary'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import { allLanguages, EN } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import Menu from '../components/Menu'
import PageLoader from './loader/PageLoader'
import './App.css'

// const Proceed = lazy(() => import('./Multisender/Proceed'))
// const Summary = lazy(() => import('./Multisender/Summary'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  // overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  // padding: 32px 16px;
  // padding-top: 32px;
  // padding-left: 16px;
  // padding-right: 16px;
  // min-height: calc(100vh - 65px);
  min-height: auto;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  background-image: url('/static/media/bg.bfd323f2.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const ProgressDiv = styled(Flex)`
  position: relative;
  max-width: 1024px;
  width: 100%;
  z-index: 5;
  height: auto;
  @media (max-width: 320px) {
    width: auto;
  }
  // margin-top: -3rem;
  // margin-bottom: -5rem;
`

export default function App() {
  const { chainId } = useWeb3React()
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 6

  const credentials: Credentials = {
    token: apiKey,
  }

  const stringTranslationsApi = new StringTranslations(credentials)

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    stringTranslationsApi
      .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
      .then((translationApiResponse) => {
        if (translationApiResponse.data.length < 1) {
          setTranslations(['error'])
        } else {
          setTranslations(translationApiResponse.data)
        }
      })
      .then(() => setTranslatedLanguage(selectedLanguage))
      .catch((error) => {
        setTranslations(['error'])
        console.error(error)
      })
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const { reload } = useReload()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <Suspense fallback={<PageLoader />}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Switch>
                {/* <Route path="/" component={Website} exact /> */}
                <Menu>
                  <BodyWrapper>
                    <Popups />
                    <Web3ReactManager>
                      {loading ? (
                        <PageLoader />
                      ) : (
                        <div className="dex-app">
                          <BG>
                            {isSupportedChain('MULTISENDER', chainId) ? (
                              <Route exact strict path="/" component={Multisender} />
                            ) : (
                              <NotSupported supportedChainId={getSupportedChain('MULTISENDER')} />
                            )}

                            {/* <Route exact strict path="/swap" component={Swap} /> */}

                            {/* <Route path='/' component={Multisender} exact>
                                                            {isSupportedChain('MULTISENDER', chainId) ? (
                                                            <Multisender />
                                                            ) : (
                                                            <NotSupported supportedChainId={getSupportedChain('MULTISENDER')} />
                                                            )}
                                                          </Route> */}

                            <Route
                              exact
                              path="/farms"
                              component={() => {
                                window.location.replace('https://app.sparkswap.finance/#/stake')
                                return null
                              }}
                            />
                            <Route
                              exact
                              path="/pools"
                              component={() => {
                                window.location.replace('https://app.sparkswap.finance/#/stake')
                                return null
                              }}
                            />
                            <Route
                              exact
                              path="/airdrop"
                              component={() => {
                                window.location.replace('https://app.sparkswap.finance/#/airdrop')
                                return null
                              }}
                            />
                            <Route
                              exact
                              string
                              path="/teams"
                              component={() => {
                                window.location.href = 'https://srk.finance/team'
                                return null
                              }}
                            />
                            {/* <Route component={RedirectPathToSwapOnly} /> */}
                          </BG>
                          {/* <Footer /> */}
                        </div>
                      )}
                    </Web3ReactManager>
                  </BodyWrapper>
                </Menu>
              </Switch>
              <ToastListener />
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
