import React, { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import _ from 'lodash'
import { ChainId, Currency, ETHER } from '@sparkpointio/sparkswap-sdk'
import { LinkExternal } from '@sparkpointio/sparkswap-uikit'
import { useMultiSendContract } from './useContract'
import { getDecimalAmount, toBigNumber } from '../utils/formatBalance'
import { MULTISEND_SFUEL_FEE, MULTISEND_SRK_FEE } from '../constants'
import { getEtherscanLink, getExplorerName, getMultiSendFee } from '../utils'
import useToast from './useToast'

/**
 * Given a name or address, does a lookup to resolve to an address and name
 * @param nameOrAddress ENS name or address
 */

/*
export default function useENS(
  nameOrAddress?: string | null
): { loading: boolean; address: string | null; name: string | null } {
  const validated = isAddress(nameOrAddress)
  const reverseLookup = useENSName(validated || undefined)
  const lookup = useENSAddress(nameOrAddress)

  return {
    loading: reverseLookup.loading || lookup.loading,
    address: validated || lookup.address,
    name: reverseLookup.ENSName ? reverseLookup.ENSName : !validated && lookup.address ? nameOrAddress || null : null
  }
}
*/

export function useMultisend(receivers: any, options: any, editorRef: any) {
  const { toastSuccess, toastError } = useToast()

  let feeToken
  const { chainId, account } = useWeb3React()
  const [txHash, setTxHash] = useState('')
  const ether = Currency.getEther(chainId ?? ChainId.MAINNET)
  const multiSendContract = useMultiSendContract(chainId)

  switch (options.feeToken) {
    case options.isSubscribed:
      feeToken = '0x0000000000000000000000000000000000000000000000000000'
      break
    case MULTISEND_SRK_FEE[chainId ?? ChainId.MAINNET]:
      feeToken = options.tokenList[MULTISEND_SRK_FEE[chainId ?? ChainId.MAINNET]]
      break
    case MULTISEND_SFUEL_FEE[chainId ?? ChainId.MAINNET]:
      feeToken = options.tokenList[MULTISEND_SFUEL_FEE[chainId ?? ChainId.MAINNET]]
      break
    default:
      feeToken = undefined
  }

  const fee = options.isSubscribed ? 0 : getMultiSendFee(feeToken, chainId)

  const multisend = useCallback(
    async (props) => {
      const data = receivers.map((item, i) => {
        return [item[0], getDecimalAmount(item[1]).toString()]
      })

      props.isSending(true)

      if (_.isEqual(options.sendCurrency, ether)) {
        return multiSendEth(multiSendContract, data, options, account, feeToken, fee)
          .then((hash) => {
            const { transactionHash } = hash
            setTxHash(transactionHash)
            toastSuccess(
              'Tokens sent!',
              <LinkExternal href={getEtherscanLink(chainId, transactionHash, 'transaction')}>
                View on {getExplorerName(chainId)}
              </LinkExternal>
            )
            props.isSending(false)
            setTimeout(() => {
              editorRef.current?.clear()
            }, 1500)
          })
          .catch((error: Error) => {
            props.isSending(false)
            toastError(error.message)
            console.error('Failed to send tokens', error)
          })
      }
      return multiSendToken(multiSendContract, data, options, account, feeToken, fee)
        .then((hash) => {
          const { transactionHash } = hash
          setTxHash(transactionHash)
          props.isSending(false)
          toastSuccess(
            'Tokens sent!',
            <LinkExternal href={getEtherscanLink(chainId, transactionHash, 'transaction')}>
              View on {getExplorerName(chainId)}
            </LinkExternal>
          )
          setTimeout(() => {
            editorRef.current?.clear()
          }, 1500)
        })
        .catch((error: Error) => {
          toastError('Please try again')
          props.isSending(false)
          console.error('Failed to send tokens', error)
          throw error
        })
    },
    [account, receivers, options, multiSendContract, feeToken, fee, toastSuccess, toastError, editorRef, chainId, ether]
  )
  return [txHash, multisend] as const
}

export function useIsSubscribed() {
  const { chainId, account } = useWeb3React()
  const multiSendContract = useMultiSendContract(chainId)
  const [isSubscribed, setIsSubscribed] = useState(false)

  useEffect(() => {
    // Check if chainId is undefined
    if (chainId === undefined) setIsSubscribed(false)

    // Check if account is undefined
    if (account === undefined || account === null) setIsSubscribed(false)

    // Check if chainId is not supported
    if (chainId !== ChainId.BSCTESTNET) setIsSubscribed(false)

    multiSendContract.methods
      .isSubscribed(account ?? '0x0000000000000000000000000000000000000000')
      .call()
      .then((res) => setIsSubscribed(res))
      .catch((e) => console.error('Failed to fetch data', e))
  }, [chainId, account, setIsSubscribed, multiSendContract])

  return isSubscribed
}

async function multiSendEth(contract, data: any, options: any, account, feeToken, fee) {
  if (feeToken) {
    return contract.methods.multisendEther(data, feeToken.address).send({
      from: account,
      value: getDecimalAmount(toBigNumber(options.totalAmtToSend.current.toExact())).toString(),
    })
  }
  return contract.methods.multisendEther(data).send({
    from: account,
    value: getDecimalAmount(toBigNumber(options.totalAmtToSend.current.toExact()).plus(fee)).toString(),
  })
  // try {
  // } catch (e) {
  //     return 'contract?.functions.multisendEther'
  // }
}

async function multiSendToken(contract, data: any, options: any, account, feeToken, fee) {
  if (feeToken) {
    return contract.methods.multisendToken(options.sendCurrency.address, data, feeToken.address).send({
      from: account,
    })
  }
  return contract.methods.multisendToken(options.sendCurrency.address, data).send({
    from: account,
    value: getDecimalAmount(fee).toString(),
  })
}
