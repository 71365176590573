import React, { useContext } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@sparkpointio/sparkswap-uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import { injected, bsc, walletconnect } from 'connectors'
import links from './config'
import useAuth from '../../hooks/useAuth'

const Menu: React.FC = (props) => {
  const { account, activate, deactivate, chainId } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const { login, logout } = useAuth()

  return (
    <UikitMenu
      network={chainId}
      links={links}
      account={account as string}
      login={login}
      disableDarkOption
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      {...props}
    />
  )
}

export default Menu
