import { toBigNumber } from '../../utils/formatBalance'

const bscMultisendFees = {
  srkb: toBigNumber('230000'),
  sfuel: toBigNumber('25000'),
  eth: toBigNumber('0.25'),
}

const ethMultisendFees = {
  srkb: toBigNumber('200000'),
  eth: toBigNumber('0.025'),
}
export const multisendFees = {
  '56': bscMultisendFees,
  '97': bscMultisendFees,
  '1': ethMultisendFees,
  '5': ethMultisendFees,
}
